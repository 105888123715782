<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="统计月份" class="item">
          <el-date-picker
            value-format="yyyy-MM"
            style="width:280px;"
            v-model="searchData.timeSlot"
            size="small"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          v-checkbtn="isShowBtn(AUTH_BTN.stats_funds_month_list_export)"
          type="primary"
          icon="el-icon-download"
          size="small"
          @click="handleExport"
          >导出</el-button
        >
      </div>
    </div>
    <el-divider></el-divider>
    <div class="handle-btn">
      <div></div>
      <!-- <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.merchant_withdraw_list_export)"
        type="primary"
        icon="el-icon-download"
        size="small"
        @click="handleExport"
        >导出</el-button
      > -->
    </div>
    <StatTable
      :showPagination="false"
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { DownloadAPI, getListAPI } from './api'
import StatTable from '../tp-table.vue'
import { authBtnMixin } from '@/mixins/authBtnMixin.js'
const columns = [
  {
    label: '下单月份',
    prop: 'month',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['month']}</p>
    }
  },
  {
    label: '本金',
    prop: 'principleAmount',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['principleAmount']}</p>
    }
  },
  {
    label: '应收账单金额',
    prop: 'dueBillAmount',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['dueBillAmount']}</p>
    }
  },
  {
    label: '已收本金',
    prop: 'recvPrincipleAmount',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['recvPrincipleAmount']}</p>
    }
  },
  {
    label: '本金回收率',
    prop: 'principleRecoveryRate',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['principleRecoveryRate']}</p>
    }
  },
  {
    label: '逾期本金',
    prop: 'overduePrincipleAmount',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['overduePrincipleAmount']}</p>
    }
  },
  {
    label: '本金逾期率',
    prop: 'principleOverdueRate',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['principleOverdueRate']}</p>
    }
  },
  {
    label: '盈亏',
    prop: 'profitAmount',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['profitAmount']}</p>
    }
  },
  {
    label: '正常订单待收金额',
    prop: 'normalWaitRecvAmount',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['normalWaitRecvAmount']}</p>
    }
  },
  {
    label: '预估毛利',
    prop: 'expectProfitAmount',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['expectProfitAmount']}</p>
    }
  },
  {
    label: '预估毛利率',
    prop: 'expectProfitRate',
    minWidth: '120',

    customRender(h, row) {
      return <p>{row['expectProfitRate']}</p>
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { StatTable },
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        timeSlot: []
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { timeSlot } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize }
      if (timeSlot && timeSlot.length > 0) {
        params.leftMonth = timeSlot[0]
        params.rightMonth = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res || []
      this.total = res.total || 0
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleExport() {
      const { timeSlot } = this.searchData
      let params = {}
      if (timeSlot && timeSlot.length > 0) {
        params.leftMonth = timeSlot[0]
        params.rightMonth = timeSlot[1]
      }
      DownloadAPI(params)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .el-divider--horizontal {
    margin: 20px 0 10px;
  }

  .header-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-form {
      .el-form-item {
        margin-right: 15px;
        margin-bottom: 0;
        .el-select,
        .el-input {
          width: 280px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 30%;
          }
        }
      }
      .keywords {
        .el-select .el-input {
          width: 100px;
        }

        .el-select {
          width: 100px;
        }
        .input-with-select {
          vertical-align: middle;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }

  .handle-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
